<template>
  <BCard>
    <h3 class="my-2 text-10">Pendidikan dan Pengalaman Kerja</h3>
    <BRow class="gap-[8px]">
      <b-col md="8">
        <b-form-group
          label="Pendidikan terakhir"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="Education"
            rules="required"
          >
            <VSelect
              :value="education"
              label="text"
              :reduce="option => option.value"
              :options="educationOptions"
              :filterable="true"
              placeholder="Pilih referensi kamu"
              @input="$emit('update:education', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Pengalaman kerja"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="Experience Status"
            rules="required"
          >
            <b-form-radio-group
              :checked="hasWorkExperience"
              class="mt-50"
              :options="experienceStatusOptions"
              @input="$emit('update:hasWorkExperience', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="hasWorkExperience" md="8">
        <b-form-group
          label="Deskripsi Pengalaman"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="Deskripsi pengalaman"
            rules="min:5"
          >
            <b-form-textarea
              :value="descriptionExperience"
              placeholder="Isi pengalaman kamu"
              @input="$emit('update:descriptionExperience', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { capitalize } from '@/libs/helpers'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationProvider,
    VSelect,
  },
  props: {
    education: {
      type: String,
      default: null,
    },
    descriptionExperience: {
      type: String,
      default: '',
    },
    hasWorkExperience: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      capitalize,
      educationOptions: [
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SMA/SMK', value: 'SMA/SMK' },
        { text: 'Sarjana', value: 'Sarjana' },
      ],
      experienceStatusOptions: [
        { text: 'Ada', value: 1 },
        { text: 'Tidak ada', value: 0 },
      ],
    }
  },
}
</script>
