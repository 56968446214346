<template>
  <div>
    <!-- <div class="text-black text-2xl font-semibold mb-1">
      Tambah Partner
    </div> -->
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <!-- eslint-disable -->
        <div class="h-[calc(100vh-270px)] overflow-y-auto">
          <Information
            :email="email"
            :fullname="fullname"
            :nickname="nickname"
            :phone="phone"
            :username="username"
            :gender="gender"
            :subdistrictId="subdistrictId"
            :address="address"
            :listSubdistrict="listSubdistrict"
            :imageFile="imageFile"
            :imageInitialFile="imageInitialFile"
            :birthDate="birthDate"
            :maritalStatus="maritalStatus"
            @update:email="val => (email = val)"
            @update:fullname="val => (fullname = val)"
            @update:nickname="val => (nickname = val)"
            @update:phone="val => (phone = val)"
            @update:username="val => (username = val)"
            @update:gender="val => (gender = val)"
            @update:birthDate="val => (birthDate = val)"
            @update:maritalStatus="val => (maritalStatus = val)"
            @update:nik="val => (nik = val)"
            @update:subdistrictId="val => (subdistrictId = val)"
            @update:address="val => (address = val)"
            @search:subdistrictId="val => onSearchSubdistrict(val)"
            @update:imageFile="val => (imageFile = val)"
          />
          <WorkEducation
            :education="education"
            :hasWorkExperience="hasWorkExperience"
            :descriptionExperience="descriptionExperience"
            @update:education="val => (education = val)"
            @update:hasWorkExperience="val => (hasWorkExperience = val)"
            @update:descriptionExperience="val => (descriptionExperience = val)"
          />
          <BankAccounts
            :bankCode="bankCode"
            :bankNo="bankNo"
            :bankOwnerName="bankOwnerName"
            @update:bankCode="val => (bankCode = val)"
            @update:bankNo="val => (bankNo = val)"
            @update:bankOwnerName="val => (bankOwnerName = val)"
          />
          <Portfolio
            :portfoliosExist="portfolios"
            @update:portfolios="val => (portfolios = val)"
          />
          <Skills
            :skillsDefault="talentSkills"
            @update:talentSkills="val => (talentSkills = val)"
          />
          <Password
            :password="password"
            :confirmationPassword="confirmationPassword"
            @update:password="val => (password = val)"
            @update:confirmationPassword="val => (confirmationPassword = val)"
          />
        </div>

        <div
          class="d-flex justify-content-end bg-white p-2 shadow-lg rounded"
        >
          <button
            @click.prevent="$router.back()"
            type="kembali"
            class="mr-50 button-secondary"
          >
            Kembali
          </button>
          <button
            class="button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="editAction ? $bvModal.show('modalConfirmation') : submit()"
          >
            <b-spinner v-if="loadingSubmit" small />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalConfirmation @submit="submit()" :loadingSubmit="loadingSubmit" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import ModalConfirmation from './ModalConfirmationSave.vue'
import BankAccounts from './form-group/BankAccounts.vue'
import Skills from './form-group/Skills.vue'
import Portfolio from './form-group/Portfolio.vue'
import Password from './form-group/Password.vue'
import Information from './form-group/Information.vue'
import WorkEducation from './form-group/WorkEducation.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationObserver,
    ModalConfirmation,
    Skills,
    Portfolio,
    Password,
    // DataKomerce,
    WorkEducation,
    BankAccounts,
    Information,
  },
  props: ['detailTalent', 'buttonBackActive'],
  data() {
    return {
      selfRegister: this.$route.name === 'talent-register',
      loading: false,
      loadingSubmit: false,
      submitErrors: '',

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      fullname: '',
      nickname: '',
      username: '',
      gender: null,
      birthDate: null,
      maritalStatus: null,

      subdistrictId: null,
      address: '',

      education: '',
      hasWorkExperience: 0,
      descriptionExperience: '',

      phone: '',
      email: '',
      bankCode: '',
      bankNo: '',
      bankOwnerName: '',
      password: '',
      confirmationPassword: '',

      subdistrictKeyword: '',

      id: this.$route.params.id,
      talentId: null,

      banks: [],
      imageFile: null,
      imageInitialFile: null,

      listSubdistrict: [],
      listBank: [],
      talentSkills: [],
      portfolios: [],

      genderOptions: [
        { text: 'Laki-laki', value: 1 },
        { text: 'Perempuan', value: 2 },
      ],
      maritalOptions: [
        { text: 'Belum Menikah', value: 0 },
        { text: 'Menikah', value: 1 },
      ],
      educationOptions: [
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SMA/SMK', value: 'SMA/SMK' },
        { text: 'Sarjana', value: 'Sarjana' },
      ],
      experienceStatusOptions: [
        { text: 'Ada', value: 1 },
        { text: 'Tidak ada', value: 0 },
      ],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      alertError,
      alertSuccess,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  watch: {
    provinceId(_, oldValue) {
      this.regencyId = oldValue ? '' : this.regencyId
      this.loadRegencies()
    },
    regencyId(_, oldValue) {
      this.districtId = oldValue ? '' : this.districtId
      this.loadDistricts()
    },
  },
  async mounted() {
    this.getListBank()
    if (this.editAction) this.loadForm()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true
      this.submitErrors = ''

      const formData = new FormData()
      formData.append('full_name', this.fullname)
      formData.append('nick_name', this.nickname)
      formData.append('username', this.username)
      formData.append('gender', this.gender)
      formData.append('birth_date', this.birthDate)
      formData.append('marital_status', this.maritalStatus)

      formData.append('subdistrict_id', (!Number.isInteger(this.subdistrictId)) ? this.detailTalent.subdistrict_id : this.subdistrictId)
      formData.append('address', this.address)

      formData.append('education', this.education)
      formData.append('has_work_experience', this.hasWorkExperience)
      formData.append('description_experience', this.descriptionExperience)
      formData.append('skill_ids', this.talentSkills.map(e => e.skill_id).join(','))

      formData.append('no_hp', this.phone)
      formData.append('email', this.email)
      formData.append('bank_code', this.bankCode)
      formData.append('bank_no', this.bankNo)
      formData.append('bank_owner_name', this.bankOwnerName)

      if (this.confirmationPassword !== '') formData.append('confirmation_password', this.confirmationPassword)
      if (this.password !== '') formData.append('password', this.password)

      if (this.imageFile) formData.append('photo_profile', this.imageFile)

      if (!this.editAction && !this.buttonBackActive) {
        const url = 'v1/talents/store'
        await komtimAxiosIns
          .post(url, formData)
          .then(async response => {
            if (
              response.data.success !== undefined
                  && !response.data.success
            ) {
              return
            }

            const { data } = response.data
            this.talentId = data.id
            await this.submitAllPortfolios()

            this.$emit('submitted', data.id)
          })
          .catch(error => {
            this.alertError(error)
          })
          .finally(() => {
            this.loadingSubmit = false
          })
      }
      if (this.editAction || this.buttonBackActive) {
        const url = `v1/talents/${this.id ? this.id : this.talentId}/update`
        await komtimAxiosIns
          .put(url, formData)
          .then(async response => {
            if (
              response.data.success !== undefined
                  && !response.data.success
            ) {
              return
            }

            const { data } = response.data
            this.talentId = data.id
            await this.submitAllPortfolios()
          })
          .catch(error => {
            this.alertError(error)
            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(
                  error.response.data.data,
                ).map(([key, value]) => [key, value[0]]),
              )
            }
          })
          .finally(() => {
            this.loadingSubmit = false
          })
      }
    },
    loadForm() {
      this.fullname = this.detailTalent.full_name
      this.username = this.detailTalent.username
      this.nickname = this.detailTalent.nick_name
      this.gender = this.detailTalent.gender
      this.birthDate = this.detailTalent.birth_date
      this.maritalStatus = this.detailTalent.marital_status
      this.address = this.detailTalent.address
      this.education = this.detailTalent.education
      this.hasWorkExperience = this.detailTalent.has_work_experience
      this.descriptionExperience = this.detailTalent.description_experience
      this.phone = this.detailTalent.no_hp
      this.email = this.detailTalent.Email
      this.bankCode = this.detailTalent.bank_code
      this.bankNo = this.detailTalent.bank_no
      this.bankOwnerName = this.detailTalent.bank_owner_name
      this.talentSkills = this.detailTalent.talent_skills
      this.portfolios = this.detailTalent.talent_portfolios

      this.subdistrictId = {
        district_name: this.detailTalent.district_name,
        province_name: this.detailTalent.province_name,
        regency_name: this.detailTalent.regency_name,
        subdistrict_id: this.detailTalent.subdistrict_id,
        subdistrict_name: this.detailTalent.subdistrict_name,
        label: `${this.detailTalent.subdistrict_name}, ${this.detailTalent.district_name}, ${this.detailTalent.regency_name}, ${this.detailTalent.province_name}`,
      }

      if (this.detailTalent.photo_profile_url) this.imageInitialFile = this.detailTalent.photo_profile_url
    },
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    async getFile(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const filename = url.split('/').pop()

      return new File([blob], filename, { type: blob.type })
    },
    capitalize(value) {
      return value
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    uploadImage(e) {
      const img = e.target.value
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
        this.imageFile = ''
      } else {
        this.imageFile = img
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    async submitAllPortfolios() {
      for (let i = 0; i < this.portfolios.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        if (this.portfolios[i].id) {
          await this.updatePortfolio(this.portfolios[i], this.portfolios[i].id)
        } else {
          await this.submitPortfolio(this.portfolios[i])
        }
      }

      if (this.editAction) {
        this.loadingSubmit = false
        this.alertSuccess('Berhasil mengubah data')
        this.$router.push({ name: 'talent' })
      } else {
        this.alertSuccess('Berhasil menambahkan data')
        this.loadingSubmit = false
      }
    },
    async submitPortfolio(data) {
      const formData = new FormData()
      formData.append('title', data.title)
      formData.append('description', data.description)
      formData.append('type', data.type)
      formData.append('talent_id', this.talentId)
      formData.append('file_url', data.file_url)
      formData.append('file', data.file)

      const url = 'v1/talent_portfolios/store'
      await komtimAxiosIns
        .post(url, formData)
        .then(async response => {
          //
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async updatePortfolio(data, id) {
      const formData = new FormData()
      formData.append('title', data.title)
      formData.append('description', data.description)
      formData.append('type', data.file ? 'file' : 'link')
      formData.append('talent_id', this.talentId)
      formData.append('file_url', data.file_url || data.portfolio_url)
      formData.append('file', data.file)

      const url = `v1/talent_portfolios/${id}/update`
      await komtimAxiosIns
        .put(url, formData)
        .then(async response => {
          //
        })
        .catch(error => {
          this.alertError(error)
        })
    },
  },
}
</script>
