<template>
  <BCard>
    <h3 class="text-10">
      Keahlian
    </h3>
    <p class="text-muted mt-1 mb-[32px]">
      Tambahkan keahlian talent untuk ditampilkan di bagian talent pool
    </p>
    <BRow class="gap-[8px]">
      <BCol md="12">
        <div class="d-flex flex-wrap gap-[12px] mb-1">
          <div
            v-for="(e, i) in skillsDefault"
            :key="i"
            class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"
          >
            <span>{{ e.skill_name }}</span>
            <span
              class="k-close text-[25px] cursor-pointer"
              @click="skills.splice(i, 1)"
            />
          </div>
        </div>
        <BButton
          v-if="!showAddSkills"
          variant="outline-primary"
          @click="showAddSkills = !showAddSkills"
        >
          + Tambah Keahlian
        </BButton>
        <BCol>
          <VSelect
            v-if="showAddSkills"
            v-model="skill"
            label="name"
            :options="listSkills"
            placeholder="Tambah Keahlian"
            :selectable="skill => !skills.map(e => e.skill_id).includes(skill.id)"
            @input="handleInput($event)"
          />
        </BCol>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    skillsDefault: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showAddSkills: false,
      skills: [],
      listSkills: [],
      skill: null,
    }
  },
  watch: {
    skills(newVal) {
      this.$emit('update:talentSkills', newVal)
    },
    skillsDefault(newVal) {
      this.skills = newVal
    },
  },
  mounted() {
    this.getListSkills()
  },
  methods: {
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=1`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleInput(evt) {
      const { id, name } = evt
      const data = {
        skill_id: id,
        skill_name: name,
      }

      this.skills.push(data)
      this.skill = null
      this.showAddSkills = false
    },
  },
}
</script>
