<template>
  <div>
    <div class="text-black text-2xl font-semibold mb-1">
      Edit data talent
    </div>
    <BTabs
      v-model="tabIndex"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        title="Data Talent"
        active
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px] bg-white"
      >
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <!-- <Biodata :detailTalent="detailTalent" v-if="!loading"/> -->
          <Bio
            v-if="!loading"
            :detail-talent="detailTalent"
          />
        </BOverlay>
      </BTab>
      <BTab
        v-if="detailTalent.status === 'registered'"
        title="Kuisioner"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px] bg-white"
      >
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <Quiz
            v-if="!loading"
            :detail-talent="detailTalent"
          />
        </BOverlay>
      </BTab>
      <BTab
        v-if="detailTalent.status !== 'registered'"
        title="Pengalaman"
        lazy
        :active="$route.query.tab === 'experience'"
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px] bg-white"
      >
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BCard>
            <Experience v-if="!loading" />
          </BCard>
        </BOverlay>
      </BTab>
      <BTab
        v-if="detailTalent.status !== 'registered'"
        title="Riwayat Training"
        lazy
        :active="$route.query.tab === 'training'"
        title-item-class="bg-[#F8F8F8] rounded-[25px] bg-white"
      >
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BCard>
            <TrainingHistory v-if="!loading" />
          </BCard>
        </BOverlay>
      </BTab>
    </BTabs>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import isEmpty from 'lodash/isEmpty'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import Biodata from './Biodata.vue'
import Bio from './Bio.vue'
import Quiz from './Quistioner.vue'
import Experience from './Experience.vue'
import TrainingHistory from './TrainingHistory.vue'

export default {
  components: {
    Bio,
    Quiz,
    Experience,
    TrainingHistory,
  },
  data() {
    return {
      required,
      loading: true,
      loadingUpdate: false,
      error: '',
      name: '',
      icon: null,
      detailTalent: {},
      alertError,
      tabIndex: 0,
      tabs: ['data-talent', 'experience', 'training'],
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      this.$router.replace({ query: { tab } }).catch(err => {})
    },
  },
  beforeMount() {
    this.getDetailTalent()
  },
  methods: {
    async getDetailTalent() {
      this.offset = 0
      const url = `v1/talents/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.detailTalent = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
