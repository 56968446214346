<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Akun Bank
    </h3>
    <BRow class="gap-[8px]">
      <b-col
        md="8"
      >
        <b-form-group
          label="Nama Bank"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Bank"
            :rules="'required'"
          >
            <v-select
              :value="bankCode"
              label="name"
              :reduce="option => option.code"
              :options="listBank"
              :filterable="true"
              :state="errors.length > 0 ? false : null"
              placeholder="Ketik untuk mencari..."
              @input="$emit('update:bankCode', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="8"
      >
        <b-form-group
          label="No Rekening"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="No rekening"
            :rules="'required|numeric'"
          >
            <b-form-input
              :value="bankNo"
              type="text"
              :state="errors.length > 0 ? false : null"
              placeholder="123456789"
              @input="$emit('update:bankNo', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="8"
      >
        <b-form-group
          label="Nama Pemilik Rekening"
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama pemilik rekening"
            rules="required|alpha_spaces"
          >
            <b-form-input
              :value="bankOwnerName"
              type="text"
              :formatter="capitalize"
              :state="errors.length > 0 ? false : null"
              placeholder="Nama pemilik rekening"
              @input="$emit('update:bankOwnerName', $event)"
            />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationProvider,
    VSelect,
  },
  props: {
    bankCode: {
      type: String,
      default: '',
    },
    bankOwnerName: {
      type: String,
      default: '',
    },
    bankNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      listBank: [],
      capitalize,
    }
  },
  mounted() {
    this.getListBank()
  },
  methods: {
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
  },
}
</script>
