var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',[_c('h3',{staticClass:"mb-2 text-10"},[_vm._v(" Informasi Dasar ")]),_c('BRow',{staticClass:"flex-col-reverse md:flex-row"},[_c('BCol',{attrs:{"md":"8"}},[_c('BRow',{staticClass:"gap-[8px]"},[_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"value":_vm.email,"type":"email","placeholder":"Ketik email kamu","state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.$emit('update:email', $event)},"blur":function($event){return _vm.$emit('check:email', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Lengkap "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Nama Lengkap","rules":"required|min:3|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"value":_vm.fullname,"formatter":_vm.capitalize,"placeholder":"Ketik nama lengkap kamu"},on:{"input":function($event){return _vm.$emit('update:fullname', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Panggilan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Nama Panggilan","rules":"required|min:3|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"value":_vm.nickname,"formatter":_vm.capitalize,"placeholder":"Ketik nama panggilan kamu"},on:{"input":function($event){return _vm.$emit('update:nickname', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Username","rules":{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 25 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.username,"state":errors.length > 0 ? false : null,"placeholder":"Ketik username kamu"},on:{"input":function($event){return _vm.$emit('update:username', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nomor HP "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Nomor Hp","rules":"required|min:9|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"value":_vm.phone,"options":_vm.options.phone,"placeholder":"Ketik nomor hp kamu"},on:{"input":function($event){return _vm.$emit('update:phone', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Jenis Kelamin "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Jenis kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.genderOptions},on:{"input":function($event){return _vm.$emit('update:gender', $event)}},model:{value:(_vm.genderDefault),callback:function ($$v) {_vm.genderDefault=$$v},expression:"genderDefault"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal Lahir "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flatPickr',{staticClass:"form-control",attrs:{"config":{
                  altInput: true,
                  altFormat: 'j F Y',
                  dateFormat: 'Y-m-d',
                }},on:{"input":function($event){return _vm.$emit('update:birthDate', $event)}},model:{value:(_vm.bornDate),callback:function ($$v) {_vm.bornDate=$$v},expression:"bornDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Status Perkawinan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Status Perkawinan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.maritalOptions},on:{"input":function($event){return _vm.$emit('update:maritalStatus', $event)}},model:{value:(_vm.maritalDefault),callback:function ($$v) {_vm.maritalDefault=$$v},expression:"maritalDefault"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Lokasi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"value":_vm.subdistrictId,"reduce":function (option) { return option.subdistrict_id; },"options":_vm.listSubdistrict,"state":errors.length > 0
                    ? false
                    : null,"placeholder":"Desa, Kecamatan, Kab., Provinsi, Kode Pos"},on:{"input":function($event){return _vm.$emit('update:subdistrictId', $event)},"search":function (search) { return search.length > 0 ? _vm.$emit('search:subdistrictId', search) : ''; }},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                    var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(((option.subdistrict_name) + ", " + (option.district_name) + ", " + (option.regency_name) + ", " + (option.province_name)))+" ")])]}}],null,true)}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Alamat Lengkap "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Alamat","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"value":_vm.address,"placeholder":"Jl. Cinta Damai No.666, RT.003/RW.007 ","state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.$emit('update:address', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('BCol',{staticClass:"d-flex flex-col justify-start items-center gap-[32px]",attrs:{"md":"4"}},[_c('div',{staticClass:"profile-preview d-flex justify-center"},[(!_vm.imageFile && !_vm.imageInitialFile)?_c('span',{staticClass:"k-user-square text-[230px] text-[#C2C2C2]"}):_c('div',{staticClass:"img-preview d-flex items-center justify-center relative"},[(_vm.imageFile || _vm.imageInitialFile)?_c('img',{staticClass:"image-preview",attrs:{"src":_vm.imageFile ? _vm.fileUrl(_vm.imageFile) : _vm.imageInitialFile,"alt":"preview"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg","alt":"no-image"}}),_c('div',{staticClass:"absolute __overlay items-center justify-center"},[_c('button',{staticClass:"d-flex justify-center items-center bg-gray-50 rounded px-2 py-[10px] gap-10",on:{"click":function($event){return _vm.$bvModal.show('modalPreview')}}},[_c('span',{staticClass:"k-eye"}),_vm._v(" Lihat Foto ")])])])]),_c('div',{staticClass:"select-file d-flex"},[(!_vm.imageFile)?_c('div',{staticClass:"w-full"},[_vm._v(" Upload foto kamu ")]):_c('div',{staticClass:"w-full"},[_vm._v(" "+_vm._s(_vm.imgName.length > 15 ? ((_vm.imgName.substr(0, 15)) + " ... ") : _vm.imgName)+" ")]),_c('div',[_vm._v("Upload")]),_c('input',{staticClass:"custom-file",attrs:{"type":"file","name":"photo profile"},on:{"change":function($event){return _vm.uploadFile($event)}}}),(_vm.imageFile)?_c('span',{staticClass:"k-close text-danger absolute z-10 text-[32px] top-[6px] right-[80px] cursor-pointer",on:{"click":function($event){return _vm.removeFile()}}}):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }