<template>
  <BModal
    id="modalMedia"
    ref="modalMedia"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @shown="resetForm()"
    @hidden="formHidden()"
  >
    <validation-observer
      ref="formRules"
      v-slot="{ invalid }"
    >
      <div class="text-left my-1 d-flex flex-column gap-15">
        <div class="text-10 font-semibold text-black">
          Tambah Media
        </div>
        <b-col
          md="12"
          class="p-0"
        >
          <b-form-group
            label-class="font-medium text-7 text-black"
          >
            <template #label>
              Attachment <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Attachement"
              rules="required"
            >
              <div class="w-100 d-flex gap-[5px]">
                <div class="d-flex attachement w-full">
                  <input
                    id="custom-link"
                    v-model="attachementLink"
                    type="text"
                    name="custom-link"
                    placeholder="Isikan link atau upload file dokumen"
                    :disabled="fileSelected ? true : false"
                  >
                  <div
                    id="custom-file"
                    class="d-flex relative"
                  >
                    <input
                      ref="inputFile"
                      class="absolute h-100 opacity-0"
                      type="file"
                      name="attachement"
                      @change="uploadFile($event)"
                    >
                    <button class="w-100 text-danger">
                      Pilih File
                    </button>
                  </div>
                </div>
                <div
                  v-if="fileSelected"
                  class="d-flex gap-[5px]"
                >
                  <BButton
                    variant="outline-info"
                    class="ml-auto p-0 w-[40px] d-flex items-center justify-center"
                    target="_blank"
                    :href="fileUrl(fileSelected)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/eye-blue.svg"
                      alt="Komerce"
                      class="h-[20px]"
                    >
                  </BButton>
                  <BButton
                    variant="outline-danger"
                    class="ml-auto p-0 w-[40px] d-flex items-center justify-center"
                    @click="clearFile()"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                      alt="Komerce"
                      class="h-[20px]"
                    >
                  </BButton>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <BCol
          md="12"
          class="p-0"
        >
          <BFormGroup
            label-class="font-medium text-7 text-black"
          >
            <template #label>
              Judul Portofolio <span class="text-danger">*</span>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Judul Portfolio"
              :rules="{ required: true, max: 64 }"
            >
              <b-form-input
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Masukkan judul portofolio"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol
          md="12"
          class="p-0"
        >
          <BFormGroup
            label-class="font-medium text-7 text-black"
          >
            <template #label>
              Deskripsi
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Deskripsi"
              rules="min:5|max:250"
            >
              <b-form-textarea
                v-model="description"
                placeholder="Tuliskan deskripsi singkat"
                :state="
                  errors.length > 0 ? false : null
                "
              />
              <small class="text-danger">{{
                errors[0]
              }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <div class="d-flex gap-12 justify-center mt-3">
          <button
            size="md"
            variant="outline-primary"
            class="w-100 button-secondary"
            @click="$bvModal.hide('modalMedia')"
          >
            Kembali
          </button>
          <button
            :disabled="invalid || loadingSubmit"
            class="w-100 button-primary"
            @click="submit"
          >
            <BSpinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </div>
    </validation-observer>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
  size,
} from '@validations'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    dataSelected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      min,
      max,
      size,
      alertError,
      alertSuccess,
      fileSelected: null,
      attachementLink: null,
      attachmentInitialFile: '',
      loadingSubmit: false,
      description: '',
      title: '',
      id: null,
    }
  },
  methods: {
    submit() {
      const portfolio = {
        title: this.title,
        description: this.description,
        type: this.fileSelected ? 'file' : 'link',
        file_url: this.attachementLink,
        file: this.fileSelected,
        id: this.id,
      }

      if (!this.dataSelected) this.$emit('addPortfolio', portfolio)
      else this.$emit('editPortfolio', portfolio)
      this.$bvModal.hide('modalMedia')
    },
    uploadFile(e) {
      const fileSelected = e.target.value
      const fileSize = e.target.files[0].size
      const fileExtension = fileSelected.substr((fileSelected.lastIndexOf('.') + 1))
      if (fileExtension !== 'pdf') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi .pdf')
        this.fileSelected = ''
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.fileSelected = e.target.files[0]
        if (e.target.files[0].size > 2048000) {
        // eslint-disable-next-line no-alert
          alert('Ukuran File maks. 2mb')
          this.fileSelected = ''
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.fileSelected = e.target.files[0]
          this.attachementLink = fileSelected.split('\\').pop()
        }
      }
    },
    fileUrl: file => URL.createObjectURL(file),
    clearFile() {
      this.fileSelected = null
      this.attachementLink = ''
      this.$refs.inputFile.value = ''
      this.$refs.inputFile.files = null
    },
    resetForm() {
      if (!this.dataSelected) {
        this.fileSelected = null
        this.attachementLink = ''
        this.title = ''
        this.description = ''
      } else {
        this.fileSelected = this.dataSelected.file || null
        this.attachementLink = this.dataSelected.portfolio_url || this.dataSelected.file_url
        this.title = this.dataSelected.title
        this.description = this.dataSelected.description
        this.id = this.dataSelected.id
      }
    },
    formHidden() {
      this.fileSelected = null
      this.attachementLink = ''
      this.title = ''
      this.description = ''
      this.id = null
      this.$emit('hidden')
    },
  },
}
</script>

<style lang="scss" scoped>
.attachement {
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
  overflow: hidden;
}
#custom-link {
  outline: none;
  width: 100%;
}
#custom-file {
  width: 82px;
  border-left: 1px solid #E2E2E2;
}
</style>
