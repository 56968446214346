<template>
  <b-overlay
    variant="light"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <validation-observer
      ref="formRules"
      v-slot="{ invalid }"
    >
      <b-row>
        <b-col md="8">
          <!-- form -->
          <b-form>
            <b-row>
              <b-col
                class="pb-2"
                md="12"
              >
                <h3 class="mb-2 text-10">
                  Profil
                </h3>
                <b-form-row>
                  <b-col cols="4">
                    <div class="text-left mr-md-1">
                      <b-avatar
                        size="56px"
                        :src="imageFile ? fileUrl(imageFile) : imageInitialFile"
                      />
                    </div>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group
                      label-cols-md="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Foto profil"
                      >
                        <b-form-file
                          v-model="imageFile"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            imageInitialFile
                              ? imageInitialFile.split('/').pop()
                              : `Upload Foto kamu`
                          "
                          drop-placeholder="Upload foto kamu"
                          accept="image/jpeg, image/png, image/gif"
                          @change="uploadImage($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Nama lengkap"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Lengkap"
                    rules="required|min:3|max:50|alpha_spaces"
                  >
                    <b-form-input
                      v-model="fullname"
                      :state="
                        errors.length > 0 || submitErrors.name ? false : null
                      "
                      :formatter="capitalize"
                      placeholder="Isi nama lengkap kamu"
                    />
                    <small class="text-danger">{{
                      errors[0] || submitErrors.name
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Nama Panggilan"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Panggilan"
                    rules="min:3|max:50|alpha_spaces"
                  >
                    <b-form-input
                      v-model="nickname"
                      :state="
                        errors.length > 0 || submitErrors.name ? false : null
                      "
                      :formatter="capitalize"
                      placeholder="Isi nama panggilan kamu"
                    />
                    <small class="text-danger">{{
                      errors[0] || submitErrors.name
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Username"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required|min:3|max:25"
                  >
                    <b-form-input
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi username kamu"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jenis kelamin"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jenis kelamin"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="gender"
                      class="mt-50"
                      :options="genderOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Tanggal lahir"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Lahir"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="birthDate"
                      class="form-control"
                      :config="{
                        altInput: true,
                        altFormat: 'j F Y',
                        dateFormat: 'Y-m-d',
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Status pernikahan"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status pernikahan"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="maritalStatus"
                      class="mt-50"
                      :options="maritalOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h3 class="my-2 text-10">
                  Alamat Asal
                </h3>
                <b-form-group
                  label="Lokasi"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lokasi"
                    rules="required"
                  >
                    <v-select
                      v-model="subdistrictId"
                      :reduce="option => option.subdistrict_id"
                      :options="listSubdistrict"
                      :state="
                        errors.length > 0 || submitErrors.province_id
                          ? false
                          : null
                      "
                      placeholder="Ketik untuk mencari..."
                      @search="(search) => search.length > 0 ? onSearchSubdistrict(search) : ''"
                    >
                      <template
                        #selected-option-container="{ option }"
                      >
                        <div class="vs__selected">
                          {{ `${option.subdistrict_name}, ${option.district_name}, ${option.regency_name}, ${option.province_name}` }}
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{
                      errors[0] || submitErrors.province_id
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Alamat"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required|min:5"
                  >
                    <b-form-textarea
                      v-model="address"
                      :state="
                        errors.length > 0 || submitErrors.address ? false : null
                      "
                      placeholder="Isi alamat kamu"
                    />
                    <small class="text-danger">{{
                      errors[0] || submitErrors.address
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h3 class="my-2 text-10">
                  Pendidikan dan Pengalaman Kerja
                </h3>
                <b-form-group
                  label="Pendidikan terakhir"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Education"
                    rules="required"
                  >
                    <b-form-select
                      v-model="education"
                      :options="educationOptions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Pengalaman kerja"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Experience Status"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="hasWorkExperience"
                      class="mt-50"
                      :options="experienceStatusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="hasWorkExperience"
                md="12"
              >
                <b-form-group
                  label="Deskripsi Pengalaman"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi pengalaman"
                    rules="min:5"
                  >
                    <b-form-textarea
                      v-model="descriptionExperience"
                      :state="
                        errors.length > 0 || submitErrors.address ? false : null
                      "
                      placeholder="Isi pengalaman kamu"
                    />
                    <small class="text-danger">{{
                      errors[0] || submitErrors.address
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h3 class="my-2 text-10">
                  Kontak
                </h3>
                <b-form-group
                  label="No hp"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="No Hp"
                    rules="required|min:9|max:15"
                  >
                    <cleave
                      v-model="phone"
                      class="form-control"
                      :options="options.phone"
                      placeholder="080123456789"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Email"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="email"
                      type="email"
                      :state="
                        errors.length > 0 || submitErrors.email ? false : null
                      "
                      placeholder="Isi email kamu"
                    />
                    <small class="text-danger">{{
                      errors[0] || submitErrors.email
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!selfRegister"
                md="12"
              >
                <h3 class="my-2 text-10">
                  Akun Bank
                </h3>
                <b-form-group
                  label="Nama Bank"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Bank"
                    :rules="'required'"
                  >
                    <v-select
                      v-model="bankCode"
                      label="name"
                      :reduce="option => option.code"
                      :options="listBank"
                      :filterable="true"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ketik untuk mencari..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!selfRegister"
                md="12"
              >
                <b-form-group
                  label="No Rekening"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="No rekening"
                    :rules="'required|numeric'"
                  >
                    <b-form-input
                      v-model="bankNo"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="123456789"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!selfRegister"
                md="12"
              >
                <b-form-group
                  label="Nama Pemilik Rekening"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama pemilik rekening"
                    rules="required|alpha_spaces"
                  >
                    <b-form-input
                      v-model="bankOwnerName"
                      type="text"
                      :formatter="capitalize"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nama pemilik rekening"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <h3 class="my-2 text-10">
                  Password
                </h3>
                <b-form-group
                  label="Password"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    :rules="!editAction ? 'required|min:8' : 'min:8'"
                  >
                    <b-form-input
                      v-model="password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Konfirmasi password"
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Konfirmasi password"
                    :rules="!editAction ? 'required|confirmed:Password' : 'confirmed:Password'"
                  >
                    <b-form-input
                      v-model="confirmationPassword"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Masukkan ulang password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mt-3">
        <button
          type="kembali"
          class="mr-50 button-secondary"
          @click.prevent="$router.back()"
        >
          Kembali
        </button>
        <button
          class="mr-50 button-primary"
          :disabled="invalid || loadingSubmit"
          @click.prevent="submit()"
        >
          <b-spinner
            v-if="loadingSubmit"
            small
          />
          {{ editAction ? 'Simpan' : 'Simpan & Lanjutkan' }}
        </button>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormSelect,
  BFormFile,
  BAvatar,
  BFormRow,
  BOverlay,
  BFormRadioGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormRow,
    BRow,
    BCol,
    // BFormSelect,
    BFormFile,
    BAvatar,
    BFormRadioGroup,
    BOverlay,
    flatPickr,
    vSelect,
    Cleave,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['detailTalent', 'buttonBackActive'],
  data() {
    return {
      selfRegister: this.$route.name === 'talent-register',
      loading: false,
      loadingSubmit: false,
      submitErrors: '',

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      fullname: '',
      nickname: '',
      username: '',
      gender: '',
      birthDate: null,
      maritalStatus: '',

      subdistrictId: '',
      address: '',

      education: '',
      hasWorkExperience: '',
      descriptionExperience: '',

      phone: '',
      email: '',
      bankCode: '',
      bankNo: '',
      bankOwnerName: '',
      password: '',
      confirmationPassword: '',

      subdistrictKeyword: '',

      id: this.$route.params.id,
      talentId: null,

      banks: [],
      imageFile: null,
      imageInitialFile: null,

      listSubdistrict: [],
      listBank: [],

      genderOptions: [
        { text: 'Laki-laki', value: 1 },
        { text: 'Perempuan', value: 2 },
      ],
      maritalOptions: [
        { text: 'Belum Menikah', value: 0 },
        { text: 'Menikah', value: 1 },
      ],
      educationOptions: [
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SMA/SMK', value: 'SMA/SMK' },
        { text: 'Sarjana', value: 'Sarjana' },
      ],
      experienceStatusOptions: [
        { text: 'Ada', value: 1 },
        { text: 'Tidak ada', value: 0 },
      ],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      alertError,
      alertSuccess,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  watch: {
    provinceId(_, oldValue) {
      this.regencyId = oldValue ? '' : this.regencyId
      this.loadRegencies()
    },
    regencyId(_, oldValue) {
      this.districtId = oldValue ? '' : this.districtId
      this.loadDistricts()
    },
  },
  async mounted() {
    this.getListBank()
    if (this.editAction) this.loadForm()
  },
  methods: {
    submit() {
      this.loadingSubmit = true
      this.$refs.formRules.validate().then(async success => {
        if (success) {
          this.submitErrors = ''

          const formData = new FormData()
          formData.append('full_name', this.fullname)
          formData.append('nick_name', this.nickname)
          formData.append('username', this.username)
          formData.append('gender', this.gender)
          formData.append('birth_date', this.birthDate)
          formData.append('marital_status', this.maritalStatus)

          formData.append('subdistrict_id', (!Number.isInteger(this.subdistrictId)) ? this.detailTalent.subdistrict_id : this.subdistrictId)
          formData.append('address', this.address)

          formData.append('education', this.education)
          formData.append('has_work_experience', this.hasWorkExperience)
          formData.append('description_experience', this.descriptionExperience)

          formData.append('no_hp', this.phone)
          formData.append('email', this.email)
          formData.append('bank_code', this.bankCode)
          formData.append('bank_no', this.bankNo)
          formData.append('bank_owner_name', this.bankOwnerName)

          if (this.confirmationPassword !== '') formData.append('confirmation_password', this.confirmationPassword)
          if (this.password !== '') formData.append('password', this.password)

          if (this.imageFile) formData.append('photo_profile', this.imageFile)

          if (!this.editAction && !this.buttonBackActive) {
            const url = 'v1/talents/store'
            await komtimAxiosIns
              .post(url, formData)
              .then(async response => {
                if (
                  response.data.success !== undefined
                  && !response.data.success
                ) {
                  return
                }

                const { data } = response.data
                this.$emit('submitted', data.id)
                this.talentId = data.id
                this.alertSuccess('Berhasil menambahkan data')
              })
              .catch(error => {
                this.alertError(error)
                if (error.response.status === 422) {
                  this.submitErrors = Object.fromEntries(
                    Object.entries(
                      error.response.data.data,
                    ).map(([key, value]) => [key, value[0]]),
                  )
                }
              })
              .finally(() => {
                this.loadingSubmit = false
              })
          }
          if (this.editAction || this.buttonBackActive) {
            const url = `v1/talents/${this.id ? this.id : this.talentId}/update`
            await komtimAxiosIns
              .put(url, formData)
              .then(async response => {
                if (
                  response.data.success !== undefined
                  && !response.data.success
                ) {
                  return
                }

                this.alertSuccess('Berhasil mengubah data')

                if (this.editAction) this.$router.push({ name: 'talent' })
                else this.$emit('submitted', this.talentId)
              })
              .catch(error => {
                this.alertError(error)
                if (error.response.status === 422) {
                  this.submitErrors = Object.fromEntries(
                    Object.entries(
                      error.response.data.data,
                    ).map(([key, value]) => [key, value[0]]),
                  )
                }
              })
              .finally(() => {
                this.loadingSubmit = false
              })
          }
        }
      })
    },
    loadForm() {
      this.fullname = this.detailTalent.full_name
      this.username = this.detailTalent.username
      this.nickname = this.detailTalent.nick_name
      this.gender = this.detailTalent.gender
      this.birthDate = this.detailTalent.birth_date
      this.maritalStatus = this.detailTalent.marital_status
      this.address = this.detailTalent.address
      this.education = this.detailTalent.education
      this.hasWorkExperience = this.detailTalent.has_work_experience
      this.descriptionExperience = this.detailTalent.description_experience
      this.phone = this.detailTalent.no_hp
      this.email = this.detailTalent.Email
      this.bankCode = this.detailTalent.bank_code
      this.bankNo = this.detailTalent.bank_no
      this.bankOwnerName = this.detailTalent.bank_owner_name

      this.subdistrictId = {
        district_name: this.detailTalent.district_name,
        province_name: this.detailTalent.province_name,
        regency_name: this.detailTalent.regency_name,
        subdistrict_id: this.detailTalent.subdistrict_id,
        subdistrict_name: this.detailTalent.subdistrict_name,
        label: `${this.detailTalent.subdistrict_name}, ${this.detailTalent.district_name}, ${this.detailTalent.regency_name}, ${this.detailTalent.province_name}`,
      }

      if (this.detailTalent.photo_profile_url) this.imageInitialFile = this.detailTalent.photo_profile_url
    },
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    async getFile(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const filename = url.split('/').pop()

      return new File([blob], filename, { type: blob.type })
    },
    capitalize(value) {
      return value
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    uploadImage(e) {
      const img = e.target.value
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
        this.imageFile = ''
      } else {
        this.imageFile = img
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Biodata.scss';
</style>
