var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"md":"12"}},[_c('h3',{staticClass:"mb-2 text-10"},[_vm._v(" Profil ")]),_c('b-form-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-left mr-md-1"},[_c('b-avatar',{attrs:{"size":"56px","src":_vm.imageFile ? _vm.fileUrl(_vm.imageFile) : _vm.imageInitialFile}})],1)]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Foto profil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.imageInitialFile
                            ? _vm.imageInitialFile.split('/').pop()
                            : "Upload Foto kamu","drop-placeholder":"Upload foto kamu","accept":"image/jpeg, image/png, image/gif"},on:{"change":function($event){return _vm.uploadImage($event)}},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama lengkap","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required|min:3|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize,"placeholder":"Isi nama lengkap kamu"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Panggilan","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Panggilan","rules":"min:3|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize,"placeholder":"Isi nama panggilan kamu"},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Username","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:3|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Isi username kamu"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis kelamin","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Jenis kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.genderOptions},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal lahir","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                      altInput: true,
                      altFormat: 'j F Y',
                      dateFormat: 'Y-m-d',
                    }},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status pernikahan","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Status pernikahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.maritalOptions},model:{value:(_vm.maritalStatus),callback:function ($$v) {_vm.maritalStatus=$$v},expression:"maritalStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2 text-10"},[_vm._v(" Alamat Asal ")]),_c('b-form-group',{attrs:{"label":"Lokasi","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option.subdistrict_id; },"options":_vm.listSubdistrict,"state":errors.length > 0 || _vm.submitErrors.province_id
                        ? false
                        : null,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchSubdistrict(search) : ''; }},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                        var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(((option.subdistrict_name) + ", " + (option.district_name) + ", " + (option.regency_name) + ", " + (option.province_name)))+" ")])]}}],null,true),model:{value:(_vm.subdistrictId),callback:function ($$v) {_vm.subdistrictId=$$v},expression:"subdistrictId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.province_id))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.submitErrors.address ? false : null,"placeholder":"Isi alamat kamu"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2 text-10"},[_vm._v(" Pendidikan dan Pengalaman Kerja ")]),_c('b-form-group',{attrs:{"label":"Pendidikan terakhir","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Education","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.educationOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.education),callback:function ($$v) {_vm.education=$$v},expression:"education"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Pengalaman kerja","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Experience Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.experienceStatusOptions},model:{value:(_vm.hasWorkExperience),callback:function ($$v) {_vm.hasWorkExperience=$$v},expression:"hasWorkExperience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.hasWorkExperience)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Deskripsi Pengalaman","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Deskripsi pengalaman","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.submitErrors.address ? false : null,"placeholder":"Isi pengalaman kamu"},model:{value:(_vm.descriptionExperience),callback:function ($$v) {_vm.descriptionExperience=$$v},expression:"descriptionExperience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2 text-10"},[_vm._v(" Kontak ")]),_c('b-form-group',{attrs:{"label":"No hp","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"No Hp","rules":"required|min:9|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.options.phone,"placeholder":"080123456789"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 || _vm.submitErrors.email ? false : null,"placeholder":"Isi email kamu"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.email))])]}}],null,true)})],1)],1),(!_vm.selfRegister)?_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2 text-10"},[_vm._v(" Akun Bank ")]),_c('b-form-group',{attrs:{"label":"Nama Bank","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Bank","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.code; },"options":_vm.listBank,"filterable":true,"state":errors.length > 0 ? false : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.bankCode),callback:function ($$v) {_vm.bankCode=$$v},expression:"bankCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.selfRegister)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No Rekening","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"No rekening","rules":'required|numeric'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"123456789"},model:{value:(_vm.bankNo),callback:function ($$v) {_vm.bankNo=$$v},expression:"bankNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.selfRegister)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Pemilik Rekening","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama pemilik rekening","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","formatter":_vm.capitalize,"state":errors.length > 0 ? false : null,"placeholder":"Nama pemilik rekening"},model:{value:(_vm.bankOwnerName),callback:function ($$v) {_vm.bankOwnerName=$$v},expression:"bankOwnerName"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2 text-10"},[_vm._v(" Password ")]),_c('b-form-group',{attrs:{"label":"Password","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":!_vm.editAction ? 'required|min:8' : 'min:8'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null,"placeholder":"Masukkan password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Konfirmasi password","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Konfirmasi password","rules":!_vm.editAction ? 'required|confirmed:Password' : 'confirmed:Password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"password","placeholder":"Masukkan ulang password"},model:{value:(_vm.confirmationPassword),callback:function ($$v) {_vm.confirmationPassword=$$v},expression:"confirmationPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.editAction ? 'Simpan' : 'Simpan & Lanjutkan')+" ")],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }