<template>
  <div>
    <div class="text-black text-2xl font-semibold mb-2">
      Riwayat Training Program
    </div>
    <BCol class="p-0 d-flex">
      <div class="d-flex rounded-lg align-items-center border search-bar ml-[10px] h-100">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <BButton
        tag="router-link"
        to="/training-program/create"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(date)="data">
            {{ DATE_NUMBER_SLASH(data.item.start_date) }} - {{ DATE_NUMBER_SLASH(data.item.end_date) }}
          </template>
          <template #cell(level)="data">
            <div
              v-if="data.item.level === 'basic'"
              class="flex items-center basic"
            >
              Dasar
            </div>
            <div
              v-if="data.item.level === 'specific'"
              class="flex items-center specific"
            >
              Spesifik
            </div>
            <div
              v-if="data.item.level === 'mature'"
              class="flex items-center mature"
            >
              Pematangan
            </div>
          </template>
          <template #cell(notes)="data">
            <span
              v-if="data.item.notes === 'fail'"
              class="failed"
            >Tidak Lulus</span>
            <span
              v-else-if="data.item.notes === 'pass'"
              class="passed"
            >Lulus</span>
            <span
              v-else
              class="passed"
            >-</span>
          </template>
          <template #cell(score)="data">
            <b-form-input
              v-model="data.item.score"
              :disabled="true"
              @keyup.enter="errors[0] ? false : updateParticipant(data.item)"
            />
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                size="md"
                variant="outline-primary"
                :to="`/training-program/${data.item.id}/detail`"
                tag="router-link"
              >
                Lihat
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { DATE_NUMBER_SLASH, DDMMYYYY, YEAR_MONTH_DAY } from '@/libs/filterDate'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last2,
  last7,
  firstDateOfMonth,
} from '@/store/helpers'
import { alertError, alertSuccess } from '@toast'
import {
  tableTraining,
} from './config'

export default {
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableTraining,
      idItem: 0,
      filterSelected: [],
      listSkills: [],
      DATE_NUMBER_SLASH,
      DDMMYYYY,
      eventTypeSelected: [],
      levelSelected: [],
      today,
      last2,
      last7,
      firstDateOfMonth,
      alertError,
      alertSuccess,
    }
  },
  computed: {
    eventType() {
      return this.eventTypeSelected.join(',')
    },
    level() {
      return this.levelSelected.join(',')
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `name=${encodeURIComponent(this.keyword)}`
      const url = `v1/training_programs/${this.$route.params.id}/detail?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=registered&skill=${this.skill}&keyword=${this.keyword}`
        const url = `v1/training_programs?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.levelSelected = []
      this.eventTypeSelected = []
      this.getListData()
    },
    async publish(id) {
      const url = `v1/training_programs/${id}/publish`
      await komtimAxiosIns
        .post(url)
        .then(() => {
          const text = 'Berhasil mem-publish Training Program'
          this.alertSuccess(text)

          this.$bvModal.hide('confirmationPublish')
          this.getListData()
        })
        .catch(err => {
          if (err.response.data.meta.message === "can't publish in training mode") {
            this.$toast_error({ message: 'Training masih berlangsung.' })
          } else {
            this.alertError(err)
          }
        })
    },
  },
}
</script>

<style scoped>

.basic {
    background: #DCF3EB;
    border-radius: 4px;
    color: #34A770;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: fit-content;
}

.specific {
    background: #FFF2E2;
    border-radius: 4px;
    color: #FBA63C;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: fit-content;
}

.mature {
    background: #FFEDED;
    border-radius: 4px;
    color: #E31A1A;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: fit-content;
}

.failed {
    background: #FFEDED;
    border-radius: 4px;
    color: #E31A1A;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: 100%;
}

.passed {
    background: #DCF3EB;
    border-radius: 4px;
    color: #34A770;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: 100%;
}
</style>
