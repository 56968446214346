<template>
  <BModal
    id="modalDeleteExp"
    ref="modalDeleteExp"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    :centered="true"
    style="width: 600px;"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-[20px]">
        Hapus Pengalaman?
      </div>
      <p class="text-black my-[20px]">
        Apakah kamu yakin untuk menghapus pengalaman ini?
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="$bvModal.hide('modalDeleteExp')"
        >
          Batal
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 160.5px"
          block
          :disabled="loadingDelete"
          @click="$emit('delete')"
        >
          Iya
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    loadingDelete: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
