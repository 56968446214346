<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-10 w-100 p-0 my-[24px]"
      >
        <b-dropdown
          id="dropdown-1"
          class="filter"
          no-caret variant="primary"
          toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
        >
          <h5>Filter</h5>
          <template #button-content>
            <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg" class="height-[20px]"/>
          </template>
          <b-dropdown id="dropdown-2" class="w-100" toggle-class="text-left" variant="none" left no-caret dropright>
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Skill role</span>
            </template>
            <b-dropdown-form style="width: 200px">
              <b-form-checkbox
                v-model="filterSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="skill.id"
                v-for="(skill, index) in listSkills"
                :key="index"
                @change="getListTalent()"
              >
                {{skill.name}}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <b-dropdown id="dropdown-3" class="w-100" toggle-class="text-left" variant="none" left no-caret dropright>
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Pengalaman Kerja</span>
            </template>
            <b-dropdown-form style="width: 200px">
              <b-form-checkbox
                v-model="filterExperienceSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="experience.value"
                v-for="(experience, index) in filterExperienceOptions"
                :key="index"
                @change="getListTalent()"
              >
                {{experience.text}}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <h5 class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer" @click="resetFilter()">Reset filter</h5>
        </b-dropdown>
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama Partner"
            @input="handleSearch()"
            class="border-0"
          />
        </div>
        <BButton
          tag="router-link"
          :to="`/talent/${$route.params.id}/experience/create`"
          variant="primary"
          class="ml-auto px-3"
        >
          <strong>Tambah</strong>
        </BButton>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div id="table" style="height: calc(100vh - 340px); overflow-y: scroll;" @scroll="handleScroll">
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang tersimpan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(name)="data">
            <span>{{data.item.no_partner}} - {{data.item.partner_name}}</span>
          </template>
          <template #cell(skill)="data">
            <div class="flex items-center">
              <div>
                {{ data.item.skill_name }}
              </div>
            </div>
          </template>
          <template #cell(work_duration)="data">
            {{ data.item.work_duration === '0 Bulan' ? '0 Bulan' : data.item.work_duration.replace('0 Bulan','') }}
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  path: `/talent/${data.item.id}/experience/edit`,
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                >
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                @click="setIdToDelete(data.item.id)"
              >
                <span
                  class="k-trash h-100 font-medium text-black text-12"
                />
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalDelete
      :loading-delete="loadingDelete"
      @delete="deleteExperience()"
    />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableExperience } from './config'
import ModalDelete from './ModalDeleteExperience.vue'

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      loading: false,
      loadingDelete: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableExperience,
      idItem: 0,
      filterSelected: [],
      listSkills: [],
      alertError,
      alertSuccess,
      filterExperienceOptions: [
        { text: '< 1 Tahun', value: '< 1 year' },
        { text: '> 1 Tahun', value: '> 1 year' },
      ],
      filterExperienceSelected: '',
      idToDelete: null,
    }
  },
  computed: {
    skill() { return this.filterSelected.join(',') },
  },
  mounted() {
    this.getListTalentExperience()
    this.getListSkills()

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop === table.scrollHeight - table.offsetHeight) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListTalentExperience() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&skill=${this.skill}&keyword=${this.keyword}`
      const url = `v1/talent_experiences/${this.$route.params.id}?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=registered&skill=${this.skill}&keyword=${this.keyword}`
        const url = `v1/talent_experiences/${this.$route.params.id}?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListTalentExperience()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    resetFilter() {
      this.filterSelected = []
      this.getListTalent()
    },
    setIdToDelete(id) {
      this.idToDelete = id
      this.$bvModal.show('modalDeleteExp')
    },
    async deleteExperience() {
      this.loadingDelete = true
      const url = `/v1/talent_experiences/${this.idToDelete}/delete`
      await komtimAxiosIns.delete(url)
        .then(res => {
          const text = 'Berhasil menghapus data'
          this.alertSuccess(text)
          this.$bvModal.hide('modalDeleteExp')
          this.getListTalentExperience()
        })
        .catch(err => {
          this.alertError(err)
        })
        .finally(() => {
          this.loadingDelete = false
        })
    },
  },

}
</script>
