<template>
  <validation-observer ref="formRules" v-slot="{ invalid }">
    <div class="p-0">
      <b-col md="8" class="p-0">
        <b-form-group
          class="p-0"
          label="Skill Role"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            :name="`Skill Role`"
            rules="required"
          >
            <v-select
              class="w-100"
              placeholder="Pilih Skill Role kamu"
              :label="'name'"
              :clearable="true"
              :options="listSkill"
              :reduce="skill => skill.id"
              @input="getQuestioner()"
              v-model="skillId"
              :disabled="(editAction && detailTalent && detailTalent.skill_id !== 0)"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-row v-if="questionItems.length">
        <b-col
          md="8"
        >
          <b-form-group
            label-class="font-medium text-7 text-black"
            label="Kuisioner"
            label-cols-md="4"
          >
              <b-col
                v-for="(item, index) in questionItems"
                :key="`item-${item.id}`"
                md="12"
                class="p-0"
              >
                <b-form-group
                  :label="item.question"
                  :label-for="`field-${item}`"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`Kuesioner ke-${index + 1}`"
                    rules="required|max:500"
                  >
                    <b-form-textarea
                      :id="`field-${item}`"
                      v-model="item.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mt-3">
        <button
          type="kembali"
          class="mr-50 button-secondary"
          @click.prevent="editAction ? $router.back() : $emit('back')"
        >
          {{ editAction ? 'Kembali' : 'Sebelumnya' }}
        </button>
        <button
          type="submit"
          class="mr-50 button-primary"
          :disabled="loadingSubmit || invalid"
          @click.prevent="submit"
        >
          <b-spinner
            v-if="loadingSubmit"
            small
          />
          {{ editAction ? 'Simpan' : 'Submit' }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
} from 'bootstrap-vue'
import { required, min, minValue } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BRow,
    BCol,
    BSpinner,
    vSelect,
  },
  directives: {
    heightFade,
  },
  data() {
    return {
      listSkill: [],
      loading: false,
      loadingSubmit: false,
      skillId: null,
      questionItems: [],
      answersInitial: [],
      id: this.$route.params.id,
      alertError,
      alertSuccess,
    }
  },
  props: ['idTalent', 'detailTalent'],
  computed: {
    editAction() {
      return this.id !== undefined
    },
  },
  async mounted() {
    this.getListSkills()
    if (this.detailTalent) this.loadForm()
  },
  methods: {
    submit() {
      this.loadingSubmit = true
      this.$refs.formRules.validate().then(async success => {
        if (success) {
          const data = {
            skill_id: Number.isInteger(this.skillId) ? this.skillId : this.detailTalent.skill_id,
            questioner: this.questionItems.map(item => ({ questionnaire_id: item.id, answer: item.value })),
          }

          const url = `v1/talents/${this.editAction ? this.$route.params.id : this.idTalent}/store-questioner`
          await komtimAxiosIns
            .put(url, data)
            .then(() => {
              const text = 'Berhasil menambahkan data'
              const textEdit = 'Berhasil mengubah data'
              this.alertSuccess(this.editAction ? textEdit : text)

              this.$router.push({ name: 'talent' })
            })
            .catch(error => {
              this.alertError(error)

              if (error.response.status === 422) {
                this.submitErrors = Object.fromEntries(
                  Object.entries(error.response.data.data).map(
                    ([key, value]) => [key, value[0]],
                  ),
                )
              }
            })
        }
      })
    },
    async getQuestioner() {
      this.loading = true
      this.offset = 0
      const url = `v1/questioners/skill/${Number.isInteger(this.skillId) ? this.skillId : this.detailTalent.skill_id}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data

          if (this.editAction && this.detailTalent.questioner) {
            this.questionItems = data.map(question => {
              const filtered = this.detailTalent.questioner.filter(answer => (answer.questionnaire_id === question.id))[0]
              const result = {
                ...question,
                value: filtered.Answer,
              }
              return result
            })
          } else {
            this.questionItems = data
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    loadForm() {
      if (this.detailTalent?.skill_id !== 0) {
        this.skillId = this.detailTalent.skill_name
        this.getQuestioner()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import './style/Quiz.scss';
</style>
